import React from "react";

const HomeBannerImage = () => {
    return (
        <video
            src="https://ggfx-koestates.s3.eu-west-2.amazonaws.com/i.prod/koestates_video_banner_8448222559.mp4"
            alt={`${process.env.GATSBY_SITE_NAME} - Home`}
            className="banner-img"
            autoPlay="autoplay"
            playsInLine="playsinline"
            loop="true"
            muted="true"
        />
    )
}

export default HomeBannerImage